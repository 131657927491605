
.video {
    display: flex;
    flex-direction: column;
    text-align: left;
    // width: max(40%, 300px);
    flex-basis: calc(calc(90% / 2) - 30px);
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 300px;
    // height: auto;
    margin: 5px;
    background-color: lightblue;
    // max-width: 100%;
  }