.parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    background-color: #2f1d58;
    width: 90%;
    // height: 100vh;
    overflow: auto;
    flex-direction: row;
    margin: 0 auto;
    margin-bottom: 50px;
}